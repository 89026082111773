@import "styles/syncfusionCss/datepicker.css";
@import "styles/syncfusionCss/tabs.css";
@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/transitions/slide.css';
@import "styles/syncfusionCss/scheduler.css";

body {
  background-color: #f2f6ff;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f1f1;
  border-radius: 10px;
  background: inherit;
}

.spinner {
  animation: spin infinite 2s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.topbar {
  margin-left: 220px;
  height: 64px;
  max-height: 64px;
  min-height: 64px;
  transition: all 0.6s ease;
  border-bottom: 1px solid #e7eaec;
}

.mini-sidebar .topbar {
  margin-left: 0px;
  transition: all 0.6s ease;
}

.sidebar {
  width: 220px;
  height: 100vh;
  position: fixed;
  background-color: #1d51a6;
  transition: all 0.6s ease;
}

.mini-sidebar .sidebar {
  width: 0px;
  transition: all 0.6s ease;
}

.mini-sidebar .sidebar-content {
  display: none;
}

.mini-sidebar,
.page-wrapper {
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.page-wrapper {
  margin-left: 220px;
  height: calc(100vh - 64px);
}

.mini-sidebar .page-wrapper {
  margin-left: 0px;
}

.second-sidebar {
  margin-top: 65px;
  margin-left: 220px;
  height: calc(100vh - 64px);
  position: fixed;
  transition: all 0.6s ease;
}

.nav-link {
  padding: 14px 20px 14px 25px;
}

.active-link {
  border-left: 4px solid #AFCEFF;
  background: #1B4A98;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
  color: white;
}

.active-child-link {
  background: #1B4A98;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
  color: white;
}

.highlight-border {
  border-left: 4px solid #AFCEFF;
}

.nav-second-level {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease-out;
}

.nav-second-level-link {
  padding: 7px 10px 7px 52px;
  background: #1B4A98;
}

.nav-second-level-link:hover {
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f1f1;
  border-radius: 10px;
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.second-level-nav li>a {
  padding-left: 52px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-left: 4px solid #AFCEFF;
}

.second-level-nav li>a:hover {
  color: white;
}

.label {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 10px;
  text-shadow: none;
  display: inline;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.count-info .label {
  right: -3px;
  top: -3px;
  height: 20px;
  width: 20px;
  padding: 2px 4px;
  position: absolute;
}

.btn-primary {
  color: #fff;
  border: 0;
  background-color: #1d51a6;
  border-radius: 6px;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  vertical-align: middle;
}

.notification-bar-height {
  height: calc(100vh - 125px);
}

th {
  font-weight: 500 !important;
}

.card-scroll-max-height {
  max-height: calc(100vh - 250px);
}

.table-scroll-max-height {
  max-height: calc(100vh - 325px);
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: rgb(240 253 244);
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: rgb(253 242 242);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 400px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided
   toast("**hello**") */
  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.max-modal-height {
  max-height: calc(100vh - 200px);
}

table {
  width: 100%;
  table-layout: fixed;
}

.tbl-header {
  background-color: #F1F5F9;
}

.tbl-content {
  overflow-x: auto;
  margin-top: 0px;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem !important;
}

.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: rgb(151, 208, 252, 1) !important;
  box-shadow: 0 0 0 1px rgb(151, 208, 252, 1) !important;
  border-radius: 0.375rem !important;
}

.scheduler-date > .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: black !important;
  box-shadow: none !important;
  border-radius: 0.375rem !important;
}

.scheduler-date > .e-input-group:not(.e-success):not(.e-warning):not(.e-error) {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: none !important;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem !important;
  width:1px !important;
}

#timelineDate {
  display: none;
}

.scheduler-date > .e-input-group > .e-input-group-icon.e-date-icon {
  visibility: hidden;
}

.scheduler-datepicker-width > .e-calendar {
  min-width: 300px;
}

.scheduler-datepicker-width .e-calendar .e-content td.e-other-month {
  visibility: hidden;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

/*tr:first-child td:first-child {*/
/*  border-top-left-radius: 0.375rem;*/
/*}*/

/*tr:first-child td:last-child {*/
/*  border-top-right-radius: 0.375rem;*/
/*}*/

tr:last-child td:first-child {
  border-bottom-left-radius: 0.375rem;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 0.375rem;
}

tr:first-child td {
  border-top-style: solid;
}

tr td:first-child {
  border-left-style: solid;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: rgb(29 81 166);
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-size: 13px;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(29 81 166, 0.2);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: rgb(175 206 255);
  border-color: rgb(175 206 255);
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  border-radius: 7px;
  box-shadow: none;
  background: inherit;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.4);
  border-radius: 7px;
}

.inline-input {
  background-color: transparent;
  padding-left: 8px;
  padding-right: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  border: 0;
  border-radius: 6px;
}

.inline-input:hover {
  background-color: rgb(229 231 235);
  cursor: pointer;
}

.inline-input:focus {
  background-color: #fff !important;
  border: 1px solid;
}

.sub-menu-height {
  height: calc(100vh - 112px);
}

.filter-btn {
  position: fixed;
  width: 110px;
  height: 35px;
  top: 75px;
  right: 0;
  color: #FFF;
  border-radius: 45px 0 0 45px;
  text-align: left;
  box-shadow: 2px 2px 3px #999;
  transition: width .3s;
}

.filter-btn:hover {
  width: 120px;
}

.filterDatePicker{
  border-color: transparent !important;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
}

.filterDatePicker :focus{
  border-color: rgb(151 208 252) !important;
}

.filterDatePicker input{
  box-sizing: border-box !important;
  height: 37px !important;
}

.inputDatePicker input{
  box-sizing: border-box !important;
  height: 33px !important;
}

.remove-ring-shadow {
  --tw-ring-shadow: 0 0 #000 !important;
}

.hide-number-input-arrow::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.hide-number-input-arrow {
  -moz-appearance:textfield !important;
}

.truncate-td-3{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-td-2{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.min-screen-width{
  min-width: 1000px;
}

.bordered-table {
  border-collapse: collapse;
}

.bordered-table td {
  border: 1px solid #E4E4E7;
}

.bordered-table td:hover {
  box-shadow: inset 0 0 0 1px #E4E4E7;
}

.bordered-table td:last-child:hover{
  box-shadow: none;
}

.currency-col{
  text-align: right !important;
  padding-right: 1rem !important;
}

.e-right-container{
  display: none !important;
}

.table-menu-button{
  border: none;
  background-color: transparent;
}

.table-menu-button:hover{
  background-color: #E2E8F0;
}

.table-menu-button:focus{
  box-shadow: none !important;
}

.e-dropdown-popup, .e-dropdown-popup:hover{
  background-color: #FFFFFF;
}

.side-detail-height{
  height: calc(100vh - 102px)
}

.invoice-side-detail-height{
  height: calc(80vh - 102px)
}

.e-dropdown-popup ul .e-item .e-menu-icon {
  float: left;
  font-size: 15px;
  line-height: 30px;
  margin-right: 6px;
  vertical-align: middle;
  width: 1em;
}

.tab-content-table-max-height{
  max-height: calc(100vh - 227px);
}

.szh-menu {
  min-width: 4rem;
  font-size: 14px;
}

.szh-menu__item {
  padding: 0.375rem 1rem;
}

.fc-resource-area {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  width: 14% !important;
}

.staff-search {
  width: calc(14% + 6px);
}

/* Change background color of the scheduler timeline header*/
.fc-head th {
  --bg-opacity: 1;
  background-color: #968a8a;
  background-color: rgba(245, 245, 246, var(--bg-opacity));
  border-bottom: 1px solid #ddd;
}

/* Removes the vertical lines in the resource view.*/
/* .fc-resource-area td,
.fc-resource-area th {
    border-style: solid !important;
    border-width: 0px !important;
    padding: 0 !important;
    vertical-align: top !important;
} */

/* Removes the divider between resource view and timeline*/
.fc-timeline .fc-divider {
  width: 5px;
  border: 1px solid #ddd;
  background-color: white;
}

.fc-timeline .fc-col-resizer {
  cursor: initial;
}

.fc-resource-area .fc-cell-content,
.fc-timeline .fc-cell-text {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

/*Controls the lines between time slots example: 6:00am | 7:00am | etc*/
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 0px solid #ddd;
}

.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-day-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-day-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-day-view .e-content-wrap table col {
  width: 50px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-week-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-week-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-week-view .e-content-wrap table col {
  width: 50px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-work-week-view  .e-work-cells,
.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-work-week-view  .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-view.e-timeline-work-week-view  .e-content-wrap table col {
  width: 50px;
}

.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-content-wrap table col {
  width: 200px;
}

.e-appointment {
  background: none !important;
  border: none !important;
  border-radius: 3px !important;
}

.e-appointment-details{
  padding: 0 !important;
}

.e-schedule .e-timeline-view .e-alternate-cells {
  border-left-style: none;
}

.e-schedule .e-timeline-view .e-appointment.e-appointment-border, .e-schedule .e-timeline-view .e-appointment:focus, .e-schedule .e-timeline-month-view .e-appointment.e-appointment-border, .e-schedule .e-timeline-month-view .e-appointment:focus {
  box-shadow: none;
}

.e-quick-popup-wrapper .e-popup-footer {
  padding: 0;
  text-align: left;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-content {
  display: none;
}

.e-treeview .e-ul {
  padding: 0;
  overflow: hidden;
}

.e-treeview .e-list-item {
  border-bottom: 1px solid rgb(229 231 235);
}

.e-treeview .e-text-content {
  cursor: move;
  padding: 0;
}

.e-treeview .e-list-text {
  padding: 0;
  display: block;
  max-width: 264px !important;
}

.drag-wrapper {
}

.drag-cursor{
  cursor: default !important;
}

.e-schedule .e-event-resize.e-left-handler {
  z-index: 100;
}

.event-count-badge {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  color: white;
  background-color: #00c799;
  width: 16px;
  transform: translate(130%, -200%);
  font-size: 10px;
}

.e-more-popup-wrapper .e-appointment {
  height: auto;
  display: block;
  padding: 0;
}

.e-schedule .e-timeline-month-view .e-appointment .e-appointment-details {
  display: block;
}